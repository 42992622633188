import { acceptHMRUpdate, defineStore } from "pinia";
import { useClinics } from "~/store/clinics";
import { useDocs } from "~/store/docs";
import cookieHelper from "~/libs/region";
import { useApi } from "~/store/app/api";
import { useUi } from "~/store/ui";
import qs from "qs";

export const useRegion = defineStore("region", {
    state: () => ({
        regionId: 'msk',
        setRegionAction: false,
        listRegions: [],
        regionInfo: null,
        phone_rf: null,
    }),
    getters: {
        listClinicsRegion: (state) => (regionId = false) => {
            const clinicStore = useClinics();
            const listClinics = [];
            regionId = regionId || state.regionId;

            clinicStore.listClinics.forEach((item) => {
                if (item.attributes.region === regionId) {
                    listClinics.push(item.attributes.clinicId);
                }
            })

            return listClinics;
        },
        listDoctorsRegion: (state) => (regionId = false) => {
            const docStore = useDocs();
            const listDoctors = [];

            docStore.listDocs.forEach((item) => {
                if (item.attributes.hideInApp) {
                    return;
                }

                if (item.attributes.DocId.length > 0) {
                    if (item.attributes.DocId.find((docId) => state.listClinicsRegion().includes(docId.clinic.data.attributes.clinicId)) !== undefined) {
                        listDoctors.push(item.id);
                    }
                }

                // if (item.attributes.clinics.data.find((clinic) => clinic.attributes.region === regionId) !== undefined) {
                //     listDoctors.push(item.id);
                // }
            });

            return listDoctors;
        },
        getCurrentRegion: (state) => {
            return state.listRegions.find(region => region.id === state.regionId) || null;
        }
    },
    actions: {
        async fetchRegions() {
            const apiStore = useApi();

            const query = qs.stringify({
                populate: '*',
                pagination: { pageSize: 1000 },
                sort: 'sort'
            }, { encode: true });

            const url = `/api/regions?${query}`;

            const response = await apiStore.fetchData(url);

            this.listRegions = response.map(region => ({
                id: region.attributes.slug,
                name: region.attributes.title,
                clinicsCount: region.attributes.count,
            }));
        },
        async fetchInfo() {
            const apiStore = useApi();

            const postData = {
                populate: '*',
                filters: {
                    slug: this.regionId
                }
            }

            const query = qs.stringify(postData, { encode: true });
            const url = `/api/regions?${query}`;
            const response = await apiStore.fetchData(url);

            this.regionInfo = response.find(region => region.attributes.slug === this.regionId);
        },
        setRegion() {
            const clinicsStore = useClinics();
            const uiStore = useUi();

            if (!this.setRegionAction) {
                const savedRegionId = this.getRegionCookie();
                const isValidSavedRegion = savedRegionId && this.listRegions.find(region => region.id === savedRegionId);
                
                if (isValidSavedRegion) {
                    this.regionId = savedRegionId;
                } else {
                    this.regionId = 'msk';
                    uiStore.showRegionModal = true;
                }
            }

            const clinicsInRegion = this.listClinicsRegion();
            clinicsStore.setClinicId(clinicsInRegion.length > 0 ? clinicsInRegion[0] : null);

            this.setRegionAction = true;
        },
        setRegionId(id) {
            this.regionId = id;
            this.setRegionCookie(id);
        },
        getRegionId() {
            if (!this.regionId) {
                this.regionId = this.getRegionCookie();
            }
            return this.regionId;
        },
        setRegionCookie(regionId) {
            const expires = new Date();
            expires.setFullYear(expires.getFullYear() + 1);
            document.cookie = `regionId=${regionId};path=/;expires=${expires.toUTCString()}`;
        },
        getRegionCookie() {
            const cookies = document.cookie.split(';');
            const regionCookie = cookies.find(cookie => cookie.trim().startsWith('regionId='));
            return regionCookie ? regionCookie.split('=')[1] : null;
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRegion, import.meta.hot));
}
